import '../App.css';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@aws-amplify/ui-react';
import QRCode from "react-qr-code";
import { wait, getDisplayStatus, getEventKey } from '../utils/utils';
import { API_ENDPOINT } from '../utils/constants';
import { getStoryById, cleanAndAddStoryToDb, saveSingleImageByStoryId } from '../utils/db';
import Book from '../components/Book';
import BookControls from '../components/BookControls';


export default function RandomStoryShowcase() {
  const [pageData, setPageData] = useState({});
  const [imageData, setImageData] = useState({});
  const [storyData, setStoryData] = useState({});
  const [existingBookData, setExistingBookData] = useState({});
  const [missingErrorVisibility, setMissingErrorVisibility] = useState(false);
  const [storyUrl, setStoryUrl] = useState('');
  const bookRef = useRef(null);
  let currentStoryId = '';
  const navigate = useNavigate();


  useEffect(() => {
    function displayImageWithBase64Data(elementId, data) {
      document.getElementById(elementId).classList.add("hideSpinner");
      document.getElementById(elementId).style.backgroundImage = `url('data:image/png;base64,${data}')`;
    }

    function displayErrorAsPageImage(elementId) {
      document.getElementById(elementId).classList.add("hideSpinner");
      document.getElementById(elementId).style.backgroundImage = "url('/img/missing_page_image.png')";
    }

    function startFetchImageLoop(imageNumber, elementId) {
      const imageQuery = `story_id=${currentStoryId}&image_num=${imageNumber}`;
      fetchAndSetImages(`${API_ENDPOINT}/fetch?${imageQuery}`, elementId, 180, 1000);
    }

    async function countDownUntilNextStory(delay) {
      let totalSeconds = delay / 1000;

      const interval = setInterval(() => {
        totalSeconds--;
        document.getElementById('countDown').innerHTML = `<b>${totalSeconds}</b>`;
      }, 1000);

      setTimeout(() => {
        clearInterval(interval);
        window.location = '/random_showcase';
      }, delay);

    }

    async function autoFlipPagesUntilEnd(delay, currentPage, pageTotal) {
      await wait(delay);
      if (currentPage < pageTotal - 1) {
        bookRef.current.pageFlip().flipNext();
        autoFlipPagesUntilEnd(delay, currentPage + 1, pageTotal);
      } else {
        shareClickHandler();
        await countDownUntilNextStory(10000);
        return;
      }
    }

    async function loadData() {
      const url = `${API_ENDPOINT}/random-story?eventKey=${getEventKey()}`

      try {
        const res = await fetch(url,
          {
            headers: {
              "eventKey": getEventKey()
            }
          });
        if (res.ok) {
          const result = await res.json();

          if (result.hasOwnProperty('error')) {
            throw new Error(result.error);
          };

          console.log("random story", result);
          currentStoryId = result[0]['story_id'];

          setStoryData(result[0]);
          setStoryUrl(`${window.location.href.split('random_showcase')[0]}story/${currentStoryId}`);

          const cleanedResult = await cleanAndAddStoryToDb(result[0]);
          setPageData(cleanedResult.pages);
          setImageData(cleanedResult.images);

          if (Object.keys(cleanedResult.images).length !== 0) {
            for (const [key] of Object.entries(cleanedResult.images)) {
              const imgNumber = cleanedResult.images[key]["url"].split("/")[2].split(".")[0];
              loadImageUntilSuccess(key, imgNumber);
            }
          }

          // images should all exist already, so shouldn't need to wait for them before flipping
          await autoFlipPagesUntilEnd(10000, 0, Object.keys(cleanedResult.pages).length);

        } else {
          throw new Error(res.status);
        }
      } catch (error) {
        if (error.message === "403") {
          navigate('/eventkey_error');
        }
        setMissingErrorVisibility(true);
        console.error(error);
      }
    }

    async function loadImageUntilSuccess(elementId, imageNumber) {
      try {
        if (Object.keys(existingBookData.imageData).length !== 0) {
          try {
            // This is hacky way of making sure the book has been added to DOM before trying to getElementById
            // should update this with an actual check in future
            await wait(1000);
            if (imageData[`image_${imageNumber}`]["url"] === "/img/missing_page_image.png") {
              displayErrorAsPageImage(elementId);
            } else {
              displayImageWithBase64Data(elementId, existingBookData.imageData[`image_${imageNumber}`]);
            }
          } catch (error) {
            console.error(error);
            startFetchImageLoop(imageNumber, elementId);
          }
        }
        else { //image data is missing from existing book
          if (imageData[`image_${imageNumber}`]["url"] === "/img/missing_page_image.png") {
            displayErrorAsPageImage(elementId);
          } else {
            startFetchImageLoop(imageNumber, elementId);
          }

        }
      } catch (error) {
        // just try and grab the image fresh if something goes wrong
        startFetchImageLoop(imageNumber, elementId);
      }
    }

    async function fetchAndSetImages(url, elementId, retries, delay) {
      return fetch(url)
        .then(res => {
          if (res.ok && res.headers.get("Content-Type") === "image/png") {
            res.text().then((result) => {
              displayImageWithBase64Data(elementId, result);
              saveSingleImageByStoryId(currentStoryId, elementId, result);
            });
            return res
          }
          else if (res.ok && res.headers.get("Content-Type") === "application/json") {
            // do stuff with json
          }
          if (retries > 0) {
            wait(delay).then(() => {
              return fetchAndSetImages(url, elementId, retries - 1, delay);
            })
          } else {
            displayErrorAsPageImage(elementId);
            console.error('Getting image via fetch timed out');
          }
          throw new Error(res.status)
        })
        .catch(error => console.log("Image doesn't exist yet, keep waiting!"));
    }

    loadData();
  }, []);

  // Book component populated with pages
  function CompleteBook() {
    let bookPages = [];

    for (let i = 1; i <= Object.keys(pageData).length; i += 1) {
      const pageImgId = "image_" + i;

      bookPages.push(
        <div className="demoPage" key={pageImgId}>
          <div className="storyIllustBox" id={pageImgId}>
            <div className="storyIllustCaption">{imageData[`image_${i}`]["caption"]}</div>
          </div>
        </div>
      );
      bookPages.push(
        <div className="demoPage" key={"page_" + i}>
          <div className="storyText">
            <p>{pageData[`page_${i}`].replace('\\', '')}</p>
          </div>
        </div>
      );
    }
    return (
      <Book ref={bookRef}>
        {bookPages}
      </Book>
    );
  }

  function loadClickHandler() {
    navigate(`/load`);
  }

  // Handling display of share modal outside of react to avoid re-renders of the book component for now (ugly way)
  function shareClickHandler() {
    document.getElementById('shareModal').classList.remove('hidden');
  }

  function closeShareHandler() {
    document.getElementById('shareModal').classList.add('hidden');
  }

  return (
    <div className="ShowStory">
      <h1 style={{ display: getDisplayStatus(missingErrorVisibility) }}> Sorry, we couldn't get your story... 😢</h1>
      <h2 className='bookTitle'>{storyData.title}</h2>
      <CompleteBook />
      <BookControls
        loadClickHandler={loadClickHandler}
        shareClickHandler={shareClickHandler}
      />
      <div className="shareModal hidden" id="shareModal">
        <div className="shareModalPanel">
          <h2>📲 Save this story</h2>
          <QRCode value={storyUrl} />
          <p>
            Loading next story in <span id='countDown'><b>10</b></span><br /><br />
            <Button variation="primary" colorTheme="success" onClick={() => closeShareHandler()}>Close</Button>
          </p>
        </div>
      </div>
    </div>
  );
}