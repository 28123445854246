import '../App.css';

export default function MissingError() {

  return (
    <div className="ErrorMessage">
      <h2>😢 Oh no! It doesn't look like there's anything here</h2>
      <h3>Please check that your URL is correct.</h3>
    </div>
  );
}