import '../App.css';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Badge, Button, TextField } from '@aws-amplify/ui-react';
import QRCode from "react-qr-code";
import { wait, getDisplayStatus, getEventKey } from '../utils/utils';
import { API_ENDPOINT } from '../utils/constants';
import { getStoryById, cleanAndAddStoryToDb, saveSingleImageByStoryId } from '../utils/db';
import Book from '../components/Book';
import BookControls from '../components/BookControls';


export default function ShowStory() {
  const [pageData, setPageData] = useState({});
  const [imageData, setImageData] = useState({});
  const [storyData, setStoryData] = useState({});
  const [existingBookData, setExistingBookData] = useState({});
  const [missingErrorVisibility, setMissingErrorVisibility] = useState(false);
  const currentStoryId = useParams().storyId;

  const navigate = useNavigate();

  // Load story data
  useEffect(() => {
    async function loadData() {
      let loadedBookData = await getStoryById(currentStoryId);

      if (loadedBookData === undefined) {
        const url = `${API_ENDPOINT}/fetch?story_id=${currentStoryId}`

        try {
          const res = await fetch(url,
            {
              headers: {
                "eventKey": getEventKey()
              }
            });
          if (res.ok) {
            const result = await res.json();

            if (result.hasOwnProperty('error')) {
              throw new Error(result.error);
            };

            setStoryData(result);

            const cleanedResult = await cleanAndAddStoryToDb(result);
            setPageData(cleanedResult.pages);
            setImageData(cleanedResult.images);
          } else {
            throw new Error(res.status);
          }
        } catch (error) {
          setMissingErrorVisibility(true);
          console.error(error);
        }

      } else {
        setExistingBookData(loadedBookData);
        setPageData(loadedBookData.pages);
        setImageData(loadedBookData.images);
      }
    }
    loadData();
  }, [currentStoryId]);



  // Load images when image list is available
  useEffect(() => {
    function displayImageWithBase64Data(elementId, data) {
      document.getElementById(elementId).classList.add("hideSpinner");
      document.getElementById(elementId).style.backgroundImage = `url('data:image/png;base64,${data}')`;
    }

    function displayErrorAsPageImage(elementId) {
      document.getElementById(elementId).classList.add("hideSpinner");
      document.getElementById(elementId).style.backgroundImage = "url('/img/missing_page_image.png')";
    }

    function startFetchImageLoop(imageNumber, elementId) {
      let rand = Math.random()
      const imageQuery = `story_id=${currentStoryId}&image_num=${imageNumber}&rand=${rand}`;
      fetchAndSetImages(`${API_ENDPOINT}/fetch?${imageQuery}`, elementId, 180, 1000);
    }

    async function loadImageUntilSuccess(elementId, imageNumber) {
      try {
        if (Object.keys(existingBookData.imageData).length !== 0) {
          try {
            // This is hacky way of making sure the book has been added to DOM before trying to getElementById
            // should update this with an actual check in future
            await wait(1000);
            if (imageData[`image_${imageNumber}`]["url"] === "/img/missing_page_image.png") {
              displayErrorAsPageImage(elementId);
            } else {
              displayImageWithBase64Data(elementId, existingBookData.imageData[`image_${imageNumber}`]);
            }
          } catch (error) {
            console.error(error);
            startFetchImageLoop(imageNumber, elementId);
          }
        }
        else { //image data is missing from existing book
          if (imageData[`image_${imageNumber}`]["url"] === "/img/missing_page_image.png") {
            displayErrorAsPageImage(elementId);
          } else {
            startFetchImageLoop(imageNumber, elementId);
          }

        }
      } catch (error) {
        // just try and grab the image fresh if something goes wrong
        startFetchImageLoop(imageNumber, elementId);
      }
    }

    async function fetchAndSetImages(url, elementId, retries, delay) {
      return fetch(url)
        .then(res => {
          if (res.ok && res.headers.get("Content-Type") === "image/png") {
            res.text().then((result) => {
              displayImageWithBase64Data(elementId, result);
              saveSingleImageByStoryId(currentStoryId, elementId, result);
            });
            return res
          }
          else if (res.ok && res.headers.get("Content-Type") === "application/json") {
            // do stuff with json
          }
          if (retries > 0) {
            wait(delay).then(() => {
              return fetchAndSetImages(url, elementId, retries - 1, delay);
            })
          } else {
            displayErrorAsPageImage(elementId);
            console.error('Getting image via fetch timed out');
          }
          throw new Error(res.status)
        })
        .catch(error => console.log("Image doesn't exist yet, keep waiting!"));
    }

    if (Object.keys(imageData).length !== 0) {
      for (const [key] of Object.entries(imageData)) {
        const imgNumber = imageData[key]["url"].split("/")[2].split(".")[0];
        loadImageUntilSuccess(key, imgNumber);
      }
    }
  }, [imageData, existingBookData.imageData, currentStoryId]);


  // Book component populated with pages
  function CompleteBook() {
    let bookPages = [];

    for (let i = 1; i <= Object.keys(pageData).length; i += 1) {
      const pageImgId = "image_" + i;

      bookPages.push(
        <div className="demoPage" key={pageImgId}>
          <div className="storyIllustBox" id={pageImgId}>
            <div className="storyIllustCaption">{imageData[`image_${i}`]["caption"]}</div>
          </div>
        </div>
      );
      bookPages.push(
        <div className="demoPage" key={"page_" + i}>
          <div className="storyText">
            <p>{pageData[`page_${i}`].replace('\\', '')}</p>
          </div>
        </div>
      );
    }
    return (
      <Book>
        {bookPages}
      </Book>
    );
  }

  function loadClickHandler() {
    navigate(`/load`);
  }

  // Handling display of share modal outside of react to avoid re-renders of the book component for now (ugly way)
  function shareClickHandler() {
    document.getElementById('shareModal').classList.remove('hidden');
  }

  function closeShareHandler() {
    document.getElementById('shareModal').classList.add('hidden');
  }

  return (
    <div className="ShowStory">
      <h1 style={{ display: getDisplayStatus(missingErrorVisibility) }}> Sorry, we couldn't get your story... 😢</h1>
      <h2 className='bookTitle'>{storyData.title}</h2>
      <div className='landscapeBadge'><Badge variation="warning">✨ Try viewing in landscape for full book</Badge></div>
      <CompleteBook />
      <BookControls
        loadClickHandler={loadClickHandler}
        shareClickHandler={shareClickHandler}
      />
      <div className="shareModal hidden" id="shareModal">
        <div className="shareModalPanel">
          <h2>📲 Share this story</h2>
          <TextField value={window.location.href} readOnly /><br />
          <QRCode value={window.location.href} />
          <p>
            <Button variation="primary" colorTheme="success" onClick={() => closeShareHandler()}>Close</Button>
          </p>
        </div>
      </div>
    </div>
  );
}