import { useEffect, useState, forwardRef} from 'react';
import HTMLFlipBook from "react-pageflip";

  const Book = forwardRef(function Book(props, ref) {
  const [bookWidth, setBookWidth] = useState(600);

  useEffect(() => {
    const longestSide = Math.max(window.innerHeight, window.innerWidth);
    const calculatedWidth = Math.min(longestSide/2 - 50, 600);
    setBookWidth(calculatedWidth);
    document.getElementsByClassName('book')[0].style.height = calculatedWidth + "px";
  }, []);

  return (
    <HTMLFlipBook
      ref={ref}
      className="book"
      width={bookWidth}
      height={bookWidth}
      maxShadowOpacity={0.5}
      mobileScrollSupport={true}
      renderOnlyPageLengthChange={true}
    >
      {props.children}

    </HTMLFlipBook>
  );
});

export default Book;