import '../App.css';

export default function EventKeyError() {

  return (
    <div className="ErrorMessage">
      <h2>😢 Oh no! It doesn't look like you're part of an event, or it's no longer valid</h2>
      <h3>Make sure you use the event QR or links to join.</h3>
    </div>
  );
}