
import { useNavigate } from 'react-router-dom';

export default function StoryCollection(props) {
  const navigate = useNavigate();
  let storyList = [];

      for (let i = 0; i < props.stories.length; i++) {
        storyList.push(
          <div className="storyItem" key={props.stories[i].story_id} onClick={() => navigate(`/story/${props.stories[i].story_id}`)}>
            <div className="storyItemImg">
            <img id={`${props.stories[i].story_id}_img`} alt={props.stories[i].title} src={`data:image/png;base64,${props.stories[i].thumbnail}`} />
            </div>
            <div className="storyItemBody">
              <p>
                {props.stories[i].title}<br />
                <span className='storyItemDesc'> {props.stories[i].text}</span>
              </p>
            </div>
          </div>
        );
      }

      if (props.stories.length === 0) {
        storyList.push(<p>📖 No stories found yet!</p>);
      }
    return storyList;
}