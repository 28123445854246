import '../App.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cleanAndAddStoryToDb } from '../utils/db';
import { API_ENDPOINT } from '../utils/constants';
import { getDisplayStatus, getUserKey, getEventKey, stashFormInputs } from '../utils/utils';
import StoryForm from '../components/StoryForm';
import FancyFrame from '../components/FancyFrame';
import { Button } from '@aws-amplify/ui-react';

export default function NewStory() {
  const [storyFormVisibility, setStoryFormVisibility] = useState(false);
  const [storyLoadingVisibility, setStoryLoadingVisibility] = useState(false);
  const [serverErrorVisibility, setServerErrorVisibility] = useState(false);

  const navigate = useNavigate();

  async function retrieveBookData(storyFormData) {
    const url = storyFormData.model === "titan" ? API_ENDPOINT + "/input-titan" : API_ENDPOINT + "/input-claude";

    stashFormInputs(storyFormData);

    storyFormData.userKey = getUserKey();
    storyFormData.eventKey = getEventKey();

    try {
      setStoryLoadingVisibility(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "eventkey": storyFormData.eventKey
        },
        body: JSON.stringify(storyFormData),
      });

      if (response.ok) {
        const result = await response.json();

        if (result.hasOwnProperty('error')) {
          throw new Error(result.error);
        };

        console.log("Success:", result);

        await cleanAndAddStoryToDb(result);

        setStoryLoadingVisibility(false);
        setStoryFormVisibility(false);
        navigate(`/story/${result.story_id}`);
      } else {
        throw new Error(response.status);
      }
    } catch (error) {
      if (error.message === "403") {
        navigate('/eventkey_error');
      }
      setStoryFormVisibility(false);
      setStoryLoadingVisibility(false);
      setServerErrorVisibility(true);
      console.error(error);
    }
  }

  function storyFormOnclickHandler(formData) {
    console.log("form data", formData);
    if (formData.piiAcknowledgement) {
      setStoryLoadingVisibility(true);
      retrieveBookData(formData);
    } else {
      alert('Please agree to the acknowledgement and tick the checkbox')
    }

  }

  function StoryFormLoading(props) {
    return (
      <div className="storyLoading" style={{ display: getDisplayStatus(props.loading) }}>
        <h2 className='loadingText'>Writing and painting your storybook 🧙‍♂️✨</h2>
        <img className="spinning" src="/img/loading_icon.png" alt="Loading icon" />
      </div>
    );
  }

  function ServerError(props) {
    return (
      <div className="serverError" style={{ display: getDisplayStatus(serverErrorVisibility) }}>
        <h3>
          Oh no! Something went wrong... 😢<br />
          Try and create your story again.
        </h3>
        <Button variation="primary" onClick={() => window.location.href = '/'}>Try again</Button>
      </div>
    );
  }

  return (
    <div className="NewStory">
      <FancyFrame>
        <StoryFormLoading loading={storyLoadingVisibility} />
        <ServerError />
        <StoryForm
          show={storyFormVisibility}
          loading={storyLoadingVisibility}
          onclickHandler={storyFormOnclickHandler}
        />
      </FancyFrame>
    </div>
  );
}