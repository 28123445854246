export default function FancyFrame(props) {
  return (
    <div className="fancyFrame">
      <div className="fancyFrameContainer">
        <div className="fancyFrameTopLeftCorner">
          <img className="fancyFrameCorner"src="/img/fancy_corner.png" alt="" />
        </div>
        <div className="fancyFrameTopRightCorner">
          <img className="fancyFrameCorner" src="/img/fancy_corner.png" alt="" />
        </div>
        <div className="fancyFrameContents">
          {props.children}
        </div>
        <div className="fancyFrameBottomLeftCorner">
          <img className="fancyFrameCorner" src="/img/fancy_corner.png" alt="" />
        </div>
        <div className="fancyFrameBottomRightCorner">
          <img className="fancyFrameCorner" src="/img/fancy_corner.png" alt="" />
        </div>
      </div>
    </div>
  );
}