import '../App.css';
import { useEffect, useState } from 'react';
import { Button } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { getMyStoryList } from '../utils/db';
import { getDisplayStatus } from '../utils/utils';
import FancyFrame from '../components/FancyFrame';
import StoryCollection from '../components/StoryCollection';

export default function UserStories() {
  const [stories, setStories] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function getStoryList() {
      const storyList = await getMyStoryList();
      setStories(storyList);
      setLoading(false);
    } 
    getStoryList();
  }, []);

  return (
    <div className="LoadStory">
      <FancyFrame>
        <div className="loadStoryContainer">
          <h2>📖 Load Your Stories</h2>
          <p>
            Select a story that you've previously read to load it as a storybook<br />
            <Button variation="primary" className="loadStoryButton" onClick={() => {navigate("/")}}>✨ Create new story instead</Button>
          </p>
          <img style={{ display: getDisplayStatus(loading) }} className="spinning" src="/img/loading_icon.png" alt="Loading icon" />
          <StoryCollection stories={stories}/>
        </div>
      </FancyFrame>
    </div>
  );
}