
import { SelectField, TextField, Button, CheckboxField, Badge } from '@aws-amplify/ui-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDisplayStatus, getPreviousFormInputs, stashFormInputs } from '../utils/utils';


export default function StoryForm(props) {

  const navigate = useNavigate();

  const formPlaceholderText = getPreviousFormInputs()

  const [storyType, setStoryType] = useState(formPlaceholderText.storyType);
  const [imageStyle, setImageStyle] = useState(formPlaceholderText.imageStyle);
  const [model, setModel] = useState(formPlaceholderText.model);
  const [protagonistName, setProtagonistName] = useState(formPlaceholderText.protagonistName);
  const [protagonistDesc, setProtagonistDesc] = useState(formPlaceholderText.protagonistDesc);
  const [villainName, setvillainName] = useState(formPlaceholderText.villainName);
  const [villainDesc, setvillainDesc] = useState(formPlaceholderText.villainDesc);
  const [npcName, setNpcName] = useState(formPlaceholderText.npcName);
  const [npcDesc, setNpcDesc] = useState(formPlaceholderText.npcDesc);
  const [customImageStyle, setCustomImageStyle] = useState(formPlaceholderText.customImageStyle);
  const [themeName, setThemeName] = useState(formPlaceholderText.themeName);
  const [piiAcknowledgement, setPiiAcknowledgement] = useState(false);

  function getFormData() {
    let formData = {
      storyType,
      imageStyle,
      model,
      protagonistName,
      protagonistDesc,
      villainName,
      villainDesc,
      npcName,
      npcDesc,
      customImageStyle,
      themeName,
      piiAcknowledgement
    }
    return formData;
  }

  return (
    <div className="storyForm" style={{ display: getDisplayStatus(!props.loading) }}>
      <h2>
        <img className="storyFormLogo" src="/img/storybook_logo.png" alt="GenAI Storybook" />
        GenAI Storybook
      </h2>
      <p>
        <strong>GenAI Storybook</strong> is a tool to help you create your own story. <br />
        Tell us what type of story you'd like to read:<br />
      </p>

      <form>
        <fieldset>
          <legend>📖 Overall Story</legend>
          <SelectField name="storyType" label="What genre of story do you like?" value={storyType} onChange={(e) => setStoryType(e.target.value)}>
            <option value="fairytale" >Bedtime Fairytale</option>
            <option value="adventure">Epic Adventure</option>
            <option value="fantasy">High Fantasy</option>
            <option value="crime">Noir Crime</option>
          </SelectField>
          <SelectField name="themeName" label="Is there a special occasion?" value={themeName} onChange={(e) => setThemeName(e.target.value)}>
            <option value="">None</option>
            <option value="xmas">Christmas</option>
            <option value="halloween">Halloween</option>
            <option value="the hero's birthday">The Hero's Birthday</option>
            <option value="the hero's wedding">The Hero's Wedding</option>
          </SelectField>
          <SelectField name="imageStyle" label="What style of pictures do you like?" value={imageStyle} onChange={(e) => setImageStyle(e.target.value)}>
            <option value="cartoon" >Cartoon</option>
            {/* <option value="gothic">Creepy Gothic</option> */}
            <option value="monet">Claude Monet</option>
            <option value="van_gogh">Vincent Van Gogh</option>
            <option value="photorealistic">Photorealistic</option>
            <option value="botticelli">Botticelli</option>
            <option value="pop-art">Pop Art</option>
            <option value="mid-century">Mid-Century illustration</option>
            <option value="tropicana">Tropicana</option>
            {/* <option value="minecraft">Minecraft</option> */}
            <option value="watercolour">Watercolour</option>
            <option value="naive">Naive/stick figures</option>
            <option value="rock">Rock painting</option>
            <option value="eqyptian">Heiroglyphics</option>
            {/* <option value="scifi">SciFi Biomechanical</option> */}
            {/* <option value="random">Random</option> */}
            {/* <option value="no-style">Let the AI decide</option> */}
          </SelectField>
          {/* <TextField name="customStyle" label="Custom Image Style" onChange={(e) => setCustomImageStyle(e.target.value)} placeholder={formPlaceholderText.customImageStyle} /> */}
          {/* <SelectField name="model" label="Which text foundation model to use?" defaultValue="claude" onChange={(e) => setModel(e.target.value)}>
            <option value="titan" disabled>Amazon Titan</option>
            <option value="claude">Anthropic Claude</option>
          </SelectField> */}
        </fieldset>

        <fieldset>
          <legend>👑 Main Character</legend>
          <TextField name="protagonistName" label="What's the main character's name?" onChange={(e) => setProtagonistName(e.target.value)} placeholder={formPlaceholderText.protagonistName} />
          <TextField name="protagonistDesc" label="How would you describe the main character?" onChange={(e) => setProtagonistDesc(e.target.value)} placeholder={formPlaceholderText.protagonistDesc} />
          {/* <TextField name="protagonistColour" label="What's the main character's favourite colour?" onChange={(e) => setProtagonistColour(e.target.value)} placeholder={formPlaceholderText.protagonistColour} /> */}
        </fieldset>

        <fieldset>
          <legend>🧚‍♀️ Main Character's Friend</legend>
          <TextField name="npcName" label="What's the name of the main character's friend?" onChange={(e) => setNpcName(e.target.value)} placeholder={formPlaceholderText.npcName} />
          <TextField name="npcDesc" label="How would you describe the main character's friend?" onChange={(e) => setNpcDesc(e.target.value)} placeholder={formPlaceholderText.npcDesc} />
          {/* <TextField name="npcColour" label="What's the main character's friend's favourite colour?" onChange={(e) => setNpcColour(e.target.value)} placeholder={formPlaceholderText.npcColour} /> */}
        </fieldset>

        <fieldset>
          <legend>😈 Villain</legend>
          <TextField name="villainName" label="What's the villain's name?" onChange={(e) => setvillainName(e.target.value)} placeholder={formPlaceholderText.villainName} />
          <TextField name="villainDesc" label="How would you describe the villain?" onChange={(e) => setvillainDesc(e.target.value)} placeholder={formPlaceholderText.villainDesc} />
          {/* <TextField name="villainColour" label="What's the villain's favourite colour?" onChange={(e) => setvillainColour(e.target.value)} placeholder={formPlaceholderText.villainColour} /> */}
        </fieldset>

        <fieldset className='piiWarning'>
          <Badge variation="warning">⚠️ Important - Agreement Required</Badge>
          <p>Stories generated will have text and images stored on our application, and is publicly viewable. Please <b>don't include any personally identifiable information</b> or anything you'd rather not show publicly! Please also review the <a href='https://aws.amazon.com/aup/'>AWS Acceptable Use Policy</a>.</p>
          <CheckboxField name="piiAcknowledgement" onChange={(e) => setPiiAcknowledgement(e.target.checked)} label="I acknowledge that (a) the stories (images & text) will be saved and viewable by the public, (b) I did not include any personal information in this form, (b) I have not knowingly used this demo to create an image that infringes a third party’s copyrighted work(s), trademark, publicity rights, or other intellectual property rights, and (c) I will comply with AWS's Acceptable Use Policy" />
        </fieldset>

        <Button variation="primary" className="navyButton" colorTheme="success" onClick={(e) => props.onclickHandler(getFormData())}>✨ Tell me a story! ✨</Button>
        <div className="iosPadding"></div>
      </form>
    </div>
  );
}