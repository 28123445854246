import './App.css';

function App(props) {
  return (
    <div className="App">
      <div className="background">
        <img src="/img/castle.png" alt="Background" />
        <div className="backgroundStrip" />
      </div>
      {props.children}
    </div>
  );
}

export default App;
