import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@aws-amplify/ui-react/styles.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { getEventKey, setEventKey } from './utils/utils';
import App from './App';
import NewStory from './routes/NewStory';
import ShowStory from './routes/ShowStory';
import UserStories from './routes/UserStories';
import EventStories from './routes/EventStories';
import RandomStoryShowcase from './routes/RandomStoryShowcase';
import EventKeyError from './routes/EventKeyError';
import MissingError from './routes/MissingError';


import reportWebVitals from './reportWebVitals';

function eventKeyValidator() {
  const url = new URL(window.location.href);
  const search = new URLSearchParams(url.search);

  let eventKey = search.get("eventKey");

  if (eventKey != null) {
    setEventKey(eventKey);
    console.log('set new eventkey', eventKey);
  } else {
    eventKey = getEventKey(); // Try getting existing stored key
    if (eventKey == null) {
      window.location = "/eventkey_error";
    }
  }
  return eventKey;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <NewStory />,
    loader: eventKeyValidator
  },
  {
    path: "story",
    element: <NewStory />,
    loader: eventKeyValidator
  },
  {
    path: "story/:storyId",
    element: <ShowStory />
  },
  {
    path: "load",
    element: <UserStories />
  },
  {
    path: "event_stories",
    element: <EventStories />,
    loader: eventKeyValidator
  },
  {
    path: "random_showcase",
    element: <RandomStoryShowcase />,
    loader: eventKeyValidator
  },
  {
    path: "eventkey_error",
    element: <EventKeyError />,
  },
  {
    path: "*",
    element: <MissingError />,
  },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App>
    <RouterProvider router={router} />
  </App>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
