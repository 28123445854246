import Dexie from 'dexie';
import { separatePageAndImageList, getEventKey, getUserKey } from './utils';
import { API_ENDPOINT } from './constants';


export const db = new Dexie('aiStorybook');

db.version(2).stores({
  stories: '++id, bucket, prompt, pages, images, imageData',
});

export async function getStoryById(storyId) {
  // DISABLE INDEXEDDB FOR NOW
  // const story = await db.stories.get(storyId);
  // return story;
  return undefined;
}
  
export async function cleanAndAddStoryToDb(response) {
  let pages = {};
  let images = {}; 
  [pages, images] = separatePageAndImageList(response);

  // DISABLE INDEXEDDB FOR NOW
  // try {
  //   await db.stories.add({
  //       id: response.story_id,
  //       bucket: response.bucket,
  //       prompt: response.prompt,
  //       pages,
  //       images,
  //       imageData: {}
  //   });
  // } catch (error) {
  //   // ignore error
  // }

  return {pages, images};
}

export async function saveSingleImageByStoryId(storyId, imageId, data) {
  // DISABLE INDEXEDDB FOR NOW
  // await db.stories.update(storyId, {
  //   [`imageData.${imageId}`]: data
  // });
}

export async function getAllStoryList() {
  const stories = await db.stories.toArray()

  return stories;
}

export async function getMyStoryList() {
  const url = `${API_ENDPOINT}/get-my-stories?limit=50&userKey=${getUserKey()}`
  const res = await fetch(url,
    {
      headers: {
        "eventKey": getEventKey()
      }
    });
  if (res.ok) {
    const result = await res.json();

    if (result.hasOwnProperty('error')) {
      throw new Error(result.error);
    };
  return result;
  } else {
    throw new Error(res.status);
  }
}

export async function getEventStoryList() {
  const url = `${API_ENDPOINT}/get-stories?limit=50&eventKey=${getEventKey()}`
  const res = await fetch(url,
    {
      headers: {
        "eventKey": getEventKey()
      }
    });
  if (res.ok) {
    const result = await res.json();

    if (result.hasOwnProperty('error')) {
      throw new Error(result.error);
    };
  return result;
  } else {
    throw new Error(res.status);
  }
}