import '../App.css';
import { useEffect, useState } from 'react';
import { Button } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { getEventStoryList } from '../utils/db';
import { getDisplayStatus } from '../utils/utils';
import FancyFrame from '../components/FancyFrame';
import StoryCollection from '../components/StoryCollection';

export default function EventStories() {
  const [stories, setStories] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function getStoryList() {
      try {
        const storyList = await getEventStoryList();
        setStories(storyList);
        setLoading(false);
      } catch (error) {
        if (error.message === '403') {
          navigate('/eventkey_error');
        }
      }
    } 
    getStoryList();
  }, []);

  return (
    <div className="LoadStory">
      <FancyFrame>
        <div className="loadStoryContainer">
          <h2>🗓️ Event Stories</h2>
          <p>
            Check out the latest stories from other event attendees<br />
            <Button variation="primary" className="loadStoryButton" onClick={() => {navigate("/")}}>✨ Create new story instead</Button>
          </p>
          <img style={{ display: getDisplayStatus(loading) }} className="spinning" src="/img/loading_icon.png" alt="Loading icon" />
          <StoryCollection stories={stories}/>
        </div>
      </FancyFrame>
    </div>
  );
}